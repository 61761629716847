/*
  This script handles the form submit and validation.
  */

  var _gametemplate = _gametemplate || {};

  _gametemplate.Module = function () {
	//init the template
	var init = function () {
		setViewPort();
		initOrientationCheck();
		onFormSubmit();
		fixAndroidAutoScroll();
		onDrinkSelect();
		displayCookieBannerOneTime();
		onCollegeChange();
	};


	var onCollegeChange = function(){

		$('#collegeSelect').on('change', function() {
		 if($(this).val()){
		  	$(this).removeClass('form-section__select--empty');
		  }
		});
	};

	var initOrientationCheck = function () {
		//check for device orientation
		if (window.DeviceOrientationEvent) {
			window.addEventListener('orientationchange', doOnOrientationChange); // Initial execution if needed

			doOnOrientationChange();
		} else {
			console.log("Sorry, your browser doesn't support Device Orientation");
		}
	};

	var displayCookieBannerOneTime = function(){
		if (localStorage !== undefined) {
		    if(!localStorage.getItem('cookie')) {
		      $('.cookie__policy').css('display','flex');
		    }
		}
	};

	var hideCookiePolicy = function(){
		if (localStorage !== undefined) {
	    	localStorage.setItem('cookie', 1);
	    	$('.cookie__policy').hide();
		}
	    
	  };


	var onTermsCheck = function () {
		$('#accept').on('click', function (event) {
			$('#termsmodal').modal('show');
			event.preventDefault();
			event.stopPropagation();
			return false;
		});
	};

	var fixAndroidAutoScroll = function(){
		if(/Android/.test(navigator.appVersion)) {
		   window.addEventListener("resize", function() {
		     if(document.activeElement.tagName == "INPUT") {
		       document.activeElement.scrollIntoView();
		     }
		  })
		} 
	};

	var acceptTerms = function (v) {
		$('#termsmodal').modal('hide');

		if (v == 1) {
			$('#accept').prop('checked', true);
		} else {
			$('#accept').prop('checked', false);
		} //termsCheck();

	};

	var termsHint = function () {
		$('#termsHint').modal('hide');
	};

	var invalidCode = function () {
		$('#invalid_code').modal('hide');
	};

	var reload = function () {
		window.location.reload();
	};

	var onTermsClick = function () {
		$('#termsmodal').modal('show');
	};

	var validateEmail = function (email) {
		var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
		return re.test(String(email).toLowerCase());
	};

	var validateDOB = function (dob) {
		var re = /^((0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/[12]\d{3})/;
		return re.test(dob);
	};

	var validateToken = function (token) {
		var re = /^[a-zA-Z0-9]{8}$/;
		return re.test(token);
	};

	var onFormSubmit = function () {
		$("#addressForm").on("submit", function (e) {
			//e.preventDefault();
			return validCheck();
		});
	};

	var validCheck = function () {
		var isValid = true;
		var firstName = $('form[name=addressForm] input[name=firstName]').val().trim();
		var lastName = $('form[name=addressForm] input[name=lastName]').val().trim();
		var email = $('form[name=addressForm] input[name=email]').val().trim();
		var college = $('form[name=addressForm] select[name=college] option:selected').val().trim();

		if (!(firstName && firstName.length > 2 && firstName.length < 50 && !validateEmail(firstName))) {
			toggleErrorMessage('input[name=firstName]',true);
			isValid = isValid ? false : isValid;

		}else{
			toggleErrorMessage('input[name=firstName]',false);
		}

		if (!(lastName && lastName.length > 2 && lastName.length < 50 && !validateEmail(lastName))) {
			toggleErrorMessage('input[name=lastName]',true);
			isValid = isValid ? false : isValid;

		}else{
			toggleErrorMessage('input[name=lastName]',false);
		}

		if (!(college)) {
			toggleErrorMessage('select[name=college]',true);
			isValid = isValid ? false : isValid;
		}else{
			toggleErrorMessage('select[name=college]',false);
		}

		if (!validateEmail(email)) {
			toggleErrorMessage('input[name=email]',true);
			isValid = isValid ? false : isValid;
		}else{
			toggleErrorMessage('input[name=email]',false);
		}

		if (!$('#termsCheckbox').is(':checked')) {
			$('#checkboxContainer').addClass('shake animated');
			setTimeout(function () {
				$('#checkboxContainer').removeClass('shake animated');
			}, 700);
			isValid = isValid ? false : isValid;
		}

		return isValid;
	}; 

	var toggleErrorMessage = function(domm,visible){

		var errorMessage = $(domm).parent().find('.form-section__span-error');
		var errorFeild = $(domm).parent().find('input,select');

		if(visible){
			errorFeild.addClass('form-section__input--error');
			errorMessage.css('display', 'block');

		}else{
			errorFeild.removeClass('form-section__input--error');
			errorMessage.hide();
		}
	};

	var isOnLandscape = 0;
    var doOnOrientationChange = function() {
        switch (window.orientation) {
            case -90:
            case 90:
                isOnLandscape = 1;
                $('.orientation').show();
                break;
            default:
                if (isOnLandscape == 1) {
                    window.location.reload();
                }

                $('.orientation').hide();
                break;
        }
    };

	var onDrinkSelect = function(){
		$('.drinks-section__option').click(function(){
			$(this).find('input[type=checkbox]').prop("checked", !$(this).find('input[type=checkbox]').prop("checked"));
		});

		$('input[name=drinks]').click(function (e) {
			e.stopPropagation();
		});
	};

	var setViewPort = function () {
		setTimeout(function () {
			var viewheight = $(window).height();
			var viewwidth = $(window).width();
			var viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewheight + "px, width=" + viewwidth + "px, initial-scale=1.0");
		}, 100);
	};

	var closeterms = function () {
		$('#buyonegetone').modal('hide');
		$('#freepudding').modal('hide');
		$('#freedrink').modal('hide');
		$('#freestarter').modal('hide'); //$('#prize_voucher').modal('show');
	};

	var showDetailsEnter = function () {
		$('#wait_sec').modal('hide');
		setActiveView('enter-prizedraw');
	};


	var oPublic = {
		init: init,
		onTermsClick: onTermsClick,
		acceptTerms: acceptTerms,
		reload: reload,
		termsHint: termsHint,
		showDetailsEnter: showDetailsEnter,
		invalidCode: invalidCode,
		closeterms: closeterms,
		slideAnimation: slideAnimation,
		hideCookiePolicy:hideCookiePolicy
	};
	return oPublic;
}();

_gametemplate.Module.init();
