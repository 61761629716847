
/**
 * Close modal and accept terms
 */
function acceptTerms() {
	closeModal();
	document.getElementById('termsCheckbox').checked = true;
	console.log(document.getElementById('termsCheckbox').checked);
}

/**
 * Close modal and decline terms
 */
function declineTerms() {
	closeModal();
	document.getElementById('termsCheckbox').checked = false;
}
