
var modalOpen = false;

/**
 * Opens the specified modal by add the show class
 * @param {string} modalId The modal to open
 */
function openModal(modalId) {
	if (event) {
		event.preventDefault();
	}
	console.log(document.getElementById(modalId));
	document.getElementById(modalId).classList.add('modal--show');
	document.getElementById('modalBackdrop').classList.add('modal__backdrop--show');
	modalOpen = true;
}

/**
 * Gets all modals on the page and closes them
 */
function closeModal() {
	const modalList = document.getElementsByClassName('modal');

	for (var index = 0; index < modalList.length; index++) {
		const modal = modalList[index];
		modal.classList.add('modal--hide');
		(function(index) {
		    setTimeout(function(){
				index.classList.remove('modal--show');
				index.classList.remove('modal--hide');
			}, 250);
		})(modal);
		
	}

	modalOpen = false;
	document.getElementById('modalBackdrop').classList.remove('modal__backdrop--show');
}

/**
 * Detects escape key press and closes modal
 */
// document.onkeydown = function(evt) {
//     evt = evt || window.event;
//     if (evt.keyCode == 27 && modalOpen) {
//         closeModal();
//     }
// };
